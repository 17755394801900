const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://8ox8tt9m6f.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://8qkj7clfej.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.5',
    HOSTNAME: 'https://teams-service.emeco.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.emeco.forwoodsafety.com',
    WEBSOCKET: 'wss://3eu94cgj67.execute-api.us-west-2.amazonaws.com/prod'
  },
};

export default config;
